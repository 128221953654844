// import { isEmpty } from 'core/packages/utils';
import { BookingStatus, BookingOrigin } from 'core/api/services/booking';

export const parseBookingToModel = (bookingExperience) => ({
    firstName: bookingExperience?.firstName,
    lastName: bookingExperience?.lastName,
    code: bookingExperience?.code,
    id: bookingExperience.id,
    triperId: bookingExperience?.triperId,
    tripId: bookingExperience?.tripId,
    experienceDetailId: bookingExperience?.experienceDetailId,
    createdOn: bookingExperience?.createdOn,
    updatedOn: bookingExperience?.updatedOn,
    status: bookingExperience.status,
    originId: bookingExperience.originId,
    date: bookingExperience?.date,
    duration: bookingExperience?.duration,
    price: bookingExperience?.price,
    currency: bookingExperience.currency,
    email: bookingExperience?.email,
    cancelReason: bookingExperience?.cancelReason,
    additional: bookingExperience?.additional,
});

export const parseBookingToModelUpdated = (booking) => ({
    experienceDetailId: booking.experienceDetailId,
    start: booking.start,
    end: booking.end,
    timezone: booking.timezone,
    price: booking.price,
    currency: booking.currency,
    capacity: booking.capacity,
    availability: booking.availability,
    scheduleId: booking?.scheduleId,
    additional: booking?.additional,
    ocupancy: booking?.ocupancy,
});

export const parseBookingToObject = (booking) => {
    return {
        code: booking.code,
        id: booking.id,
        experienceId: booking.expId,
        experienceDetailId: booking.experienceDetailId,
        email: booking.email,
        triper: `${booking.firstName} ${booking.lastName}`,
        mobilePhone: booking.mobilePhone,
        mobilePhoneCountryCode: booking.mobilePhoneCountryCode,
        experienceName: booking.experienceName,
        paidPrice: booking.paidPrice,
        totalPrice: booking.price,
        toBePaidPrice: booking.price - booking.paidPrice,
        tickets: booking.quantity,
        status: booking.status,
        originId: booking.originId,
        isEditing: false,
        date: booking.date,
        formattedDate: {
            date: booking.date.slice(0, booking.date.indexOf('T')),
            time: booking.date.slice(
                booking.date.indexOf('T') + 1,
                booking.date.lastIndexOf(':')
            ),
        },
        additional: booking?.additional,
        contractName: booking?.contractName,
        // date: booking.date.slice(0, booking.date.indexOf('T')),
        // hour: booking.date.slice(booking.date.indexOf('T') + 1, booking.date.lastIndexOf(':')),
        tripers: booking?.tripers,
    };
};

export const parseBookingReservationsToModel = (reservation) => {
    const date = new Date(reservation.dateUTC);

    return {
        id: reservation.id,
        triperId: reservation.triperId,
        tripId: reservation.tripId,
        bookingId: reservation.id,
        experienceDetailId: reservation.experienceDetailId,
        createdOn: reservation.createdOn,
        updatedOn: reservation.updatedOn,
        statusId: reservation.status,
        originId: reservation.originId,
        timeOfArrival: `${date.getUTCHours()}:${date.getUTCMinutes()}`,
        timeOfArrivalFormatted: reservation.dateUTC,
        timezone: reservation.timezone,
        duration: reservation.duration,
        totalPrice: reservation.price,
        toBePaidPrice: reservation.price - reservation.paidPrice,
        currency: 'COP', // ignore currency
        tickets: reservation.quantity,
        code: reservation.code,
        cancelReason: reservation.cancelReason,
        email: reservation.email,
        triper: `${reservation.firstName} ${reservation.lastName}`,
        phoneNumber: reservation.mobilePhone,
        paidPrice: reservation.paidPrice,
        phoneNumberCountryCode: reservation.mobilePhoneCountryCode,
        isEditing: false,
        edited: false,
        alreadyCancelled: reservation.status === BookingStatus.CANCELLED.value,
        isValidTimeOfArrival: true,
        additional: reservation?.additional,
    };
};

export const parseBookingReservationsToObject = (data, expId, subExpId) => ({
    date: data.date,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    mobilePhone: data.mobilePhone ?? '',
    mobilePhoneCountryCode: data.mobilePhoneCountryCode ?? 'us',
    quantity: parseInt(data.quantity),
    status: data.status,
    originId: data.originId,
    price: parseFloat(data.price),
    paidPrice: parseFloat(data.paidPrice),
    experienceId: parseInt(expId),
    expDetailId: parseInt(subExpId),
    additional: data.additional,
    code: data.code,
    paymentPlatform: data.paymentPlatform,
    isPendingTour: data.isPendingTour,
    items: data.items,
    redirectOrigin: window.location.origin,
});
